.App {
  min-height: 100vh;
  direction: ltr;
}

.back-color1 {
  background-color: #fcd7e7;
}

.color1 {
  color: #fcd7e7;
}

.table-cell {
  border-width: 1px 1px; /* top and bottom border width: 0, left and right border width: 1px */
  border-color: black;
  border-style: solid;
}

.custom-scrollbar {
  overflow: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 1px; /* Set the scrollbar width to 1px */
}