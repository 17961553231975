.animated-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: moveUpDown 2s linear infinite;
  }
  
  @keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px); /* Adjust the vertical distance as needed */
    }
  }

  .inline-flex.action {
    background-color: #ff0000; /* Replace #ff0000 with your desired background color */
  }

  .coin {
    width: 30px;
    height: 25px;
    border-radius: 50%;
    background-color: #D4AF37; /* Golden color */
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow effect */
  }
  
  .coin-face {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .coin-symbol {
    font-size: 8px;
    font-weight: bold;
    color: #fff; /* Text color */
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); /* Text shadow */
  }

  